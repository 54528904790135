<template>
  <div class="main-container">
    <el-tabs v-model="listState" type="card" @tab-click="handleTabClick">
      <el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
    </el-tabs>
    <!-- 粉丝增减 -->
    <div v-if="listState==0" class="tab-item">
      <div class="top">
        <span >筛选时间：</span>
        <el-date-picker
          v-model="Datevalue"
          @change="ChangeDatevalue"
          :picker-options="pickerOptionsDate"
          type="daterange"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button style="margin-left:10px;" @click="getDataCreate" type="primary">筛选</el-button>
      </div>
      <div class="interview">
        <div class="item" style="border-right:1px solid #ddd;line-height:80px;">
          微信粉丝
        </div>
        <div class="item">
          <span class="lable">新增粉丝</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.AddFansCount" placement="top-start">
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{wxFansData.AddFansCount||0}}</span>
        </div>
        <div class="item">
          <span class="lable">跑路粉丝</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.RunAwayCount" placement="top-start">
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{wxFansData.RunAwayCount||0}}</span>
        </div>
        <div class="item" >
          <span class="lable">净增粉丝数</span>
          <el-tooltip class="item-tooltip"  popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.NetAddFansCount" placement="top-start">
            <i class="el-icon-warning icon"></i>
          </el-tooltip>
          <br>
          <span class="num">{{wxFansData.NetAddFansCount||0}}</span>
        </div>
        <div class="item">
          <span class="lable">现有粉丝</span>
          <!-- <i class="el-icon-warning icon"></i> -->
          <br>
          <span class="num" style="color:#409EFF">{{wxFansData.FansCount||0}}</span>
        </div>
      </div>
      <div class="secondary-title">微信粉丝增减趋势</div>
      <div class="echart-box">
        <echart-line :echartLineData='echartLineData' :lineXAxisData='lineXAxisData'></echart-line>
      </div>
      <div class="secondary-title">详细信息</div>
       <div class="table">
        <el-table :data="wxFansData.FansIncreaseOrDecreaseDtos">
          <el-table-column label="日期" prop="Date"></el-table-column>
          <el-table-column label="新增粉丝" prop="AddFansCount"></el-table-column>
          <el-table-column label="跑路粉丝" prop="RunAwayCount"></el-table-column>
          <el-table-column label="净增粉丝" prop="NetAddFansCount"></el-table-column>
        </el-table>
        <el-pagination
          style="margin-top:20px;float:right;"
          v-if="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          :total="page.total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>

    <!-- 粉丝分层 -->
    <div v-if="listState==1" class="tab-item">
      <div class="secondary-title" style="margin-top:0;">等级</div>
      <div class="echart-box">
        <div class="left">
          <echart-pie :echartPieData='echartPieData1'></echart-pie>
        </div>
        <div class="right table">
           <el-table :data="fanLayeredData.FansLevelList" height="270">
            <el-table-column label="等级" prop="VipRoleName"></el-table-column>
            <el-table-column label="人数" prop="MemberCount"></el-table-column>
            <el-table-column label="占比">
              <template slot-scope="scope">
                {{scope.row.MemberCountRate}}%
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="secondary-title" >积分</div>
      <div class="echart-box">
        <div class="left">
          <echart-pie :echartPieData='echartPieData2'></echart-pie>
        </div>
        <div class="right table">
          <el-table :data="fanLayeredData.FansPointList" height="270">
            <el-table-column label="积分" prop="PointPeriodName"></el-table-column>
            <el-table-column label="人数" prop="MemberCount"></el-table-column>
            <el-table-column label="占比">
              <template slot-scope="scope">
                {{scope.row.MemberCountRate}}%
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 粉丝信息 -->
    <div v-if="listState==2" class="tab-item">
      <div class="secondary-title" style="margin-top:0;">微信粉丝属性</div>
      <div class="echart-box">
        <div class="left" style="width:50%">
          <echart-pie :echartPieData='echartPieData3'></echart-pie>
        </div>
        <div class="right" style="width:50%">
          <echart-pie :echartPieData='echartPieData4'></echart-pie>
        </div>
      </div>
      <div class="secondary-title">微信粉丝状况</div>
      <div class="echart-box">
        <echart-bar :echartBarData='echartBarData' ></echart-bar>
      </div>
      <div class="secondary-title">微信粉丝地域分布</div>
      <div class="echart-box" style="height:510px;">
        <div class="left" style="width:50%">
          <echart-map :echartMapData='echartMapData'></echart-map>
        </div>
        <div class="right" style="width:50%">
          <el-table :data="infoData.FansAreaDtos">
             <el-table-column
                type="index"
                label="排名">
            </el-table-column>
            <el-table-column label="地区" prop="WxProvince">
              <template slot-scope="scope">
                {{scope.row.WxProvince==''?'其它':scope.row.WxProvince}}
              </template>
            </el-table-column>
            <el-table-column label="粉丝" prop="FansCount"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!-- 粉丝互动-->
    <!-- <div v-if="listState==3" class="tab-item">
      
    </div> -->
  </div>
</template>
<script>
import echartLine from './echartLine'
import echartPie from './echartPie'
import echartBar from './echartBar'
import echartMap from './echartMap'
import apiList from '@/api/other'
export default {
  components:{echartLine,echartPie,echartBar,echartMap},
  data(){
    return {
      tooltip: {
        AddFansCount: '新增粉丝数： 统计筛选时间内，新关注的粉丝去重人数',
        RunAwayCount: '跑路粉丝数：统计筛选时间内，取消关注的粉丝去重人数',
        NetAddFansCount: '净增粉丝数：统计筛选时间内，实际增长的粉丝人数（即：新增粉丝数减去跑路粉丝）',
      },
      listState: 0,
      listStateList: [
        {
          id: 0,
          value: '粉丝增减'
        },
        {
          id: 1,
          value: '粉丝分层'
        },
        {
          id: 2,
          value: '粉丝信息'
        },
      ],
      pickerOptionsDate: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
        }
      },
      wxFansData:{},
      fanLayeredData:{},
      lineXAxisData:[],
      echartLineData:[
        {
          name: '新增粉丝',
          label:'AddFansCount',
          value: []
        },
        {
          name: '跑路粉丝',
          label:'RunAwayCount',
          value: [] 
        },
        {
          name: '净增粉丝',
          label:'NetAddFansCount',
          value: [] 
        },
      ],
      tableList:[
      ],
      page:{
        size:20,
        total:0,
        current:1
      },
      Datevalue:['',''],
      echartPieData2:[],
      echartPieData1:[],
      infoData:{},
      echartPieData3:[
        {name: '男',value: '0' },{name: '女',value:'0'  },{name: '未知',value:'0'  }
      ],
      echartPieData4:[
        {name: '购买过的粉丝',value: '100' },{name: '未购买过的粉丝',value:'500'  }
      ],
      echartBarData:[],
      echartMapData:[],
    }
  },
  created(){
    this.getDatevalue()
    this.getDataCreate()
  },
  methods:{
    getDatevalue(){
        let time = new Date()
        let yes = new Date(time - 1000 * 60 * 60 * 24 )
        let year = yes.getFullYear();
        let month = (yes.getMonth() + 1) > 9 ? (yes.getMonth() + 1) : ('0' + (yes.getMonth() + 1));
        let day = yes.getDate() > 9 ? yes.getDate() : ('0' + yes.getDate());
        let value1 = year+'-'+month+'-'+day
        let daysbefore = new Date(time - 1000 * 60 * 60 * 24*7 )
        let year2 = daysbefore.getFullYear();
        let month2 = (daysbefore.getMonth() + 1) > 9 ? (daysbefore.getMonth() + 1) : ('0' + (daysbefore.getMonth() + 1));
        let day2 = daysbefore.getDate() > 9 ? daysbefore.getDate() : ('0' + daysbefore.getDate());
        let value2 = year2+'-'+month2+'-'+day2
        this.Datevalue = [value2,value1]
    },
    async getDataCreate(){
      try{
        let data = {
          StartTime : this.Datevalue[0],
          EndTime :this.Datevalue[1]
        }
        let result  = await apiList.FansIncreaseOrDecrease(data)
        this.wxFansData = result.Result

        this.lineXAxisData = []
        this.wxFansData.FansIncreaseOrDecreaseDtos.map(t=>{
          t.Date = t.Date.trim().split(" ")[0]
          this.lineXAxisData.push(t.Date)
        })

        this.echartLineData.forEach((t)=>{
          let item = []
          this.wxFansData.FansIncreaseOrDecreaseDtos.forEach(tt=>{
              Object.keys(tt).forEach(ttt=>{//遍历一个对象的属性
                  if(t.label == ttt){
                    item.push(tt[ttt])  
                  }
              })
          })
          t.value = item
      })

      } catch(e){
        console.log('e',e)
      }
    },
    async getDataLayered(){
      try{
        let result  = await apiList.wxFansAnalyzeFanLayered()
        this.fanLayeredData = result.Result
        this.echartPieData1 = []
        this.fanLayeredData.FansLevelList.forEach((t)=> {
          let item = {
            name:t.VipRoleName,
            value:t.MemberCount
          }
          this.echartPieData1.push(item)
        });
        this.echartPieData2 = []
        this.fanLayeredData.FansPointList.forEach((t)=> {
          let item = {
            name:t.PointPeriodName,
            value:t.MemberCount
          }
          this.echartPieData2.push(item)
        });
      } catch(e){
        console.log('e',e)
      }
    },
     async getDataInfo(){
      try{
        let result  = await apiList.wxFansAnalyzeFansInfo()
        this.infoData = result.Result

        this.echartPieData3 = [
          {name: '男',value: this.infoData.BoyCount },{name: '女',value:this.infoData.GirlCount  },{name: '未知',value:this.infoData.UnknownGenderCount  }
        ]

        this.echartPieData4 = [
          {name: '购买过的粉丝',value:this.infoData.HavePayOrderFansCount},{name: '未购买过的粉丝',value:this.infoData.NotPayOrderFansCount}
        ]
        this.echartBarData = [
          this.infoData.RunAwayCount,this.infoData.FansCount
        ]

        this.echartMapData = []
        this.infoData.FansAreaDtos.forEach((t)=> {
          let item = {
            name:t.WxProvince.replace('省', ''),
            value:t.FansCount
          }
          this.echartMapData.push(item)
        });
      } catch(e){
        console.log('e',e)
      }
    },
    //改变日期
    ChangeDatevalue(){
    },
    handleTabClick(tab){
      if(tab.name == 0){
        this.getDataCreate()
      }else if(tab.name == 1){
        this.getDataLayered()
      }else{
        this.getDataInfo()
      }
    },
    handleSizeChange(val){
      this.page.size = val
    },
    handleCurrentChange(val){
      this.page.current = val
    },
  }
}
</script>
<style lang="less" scoped>
.main-container{
  background-color: #fff;
  padding: 15px;
  .secondary-title{
    margin-top:20px;
    padding: 10px;
    background-color: #eee;
  }
  .echart-box{
    padding:10px;
    width: 100%;
    height: 300px;
    margin-top: 10px;
    border: 1px solid #ddd;
    position: relative;
    overflow: hidden;
    .left{
      width: 50%;
      height: 100%;
      float: left;
    }
    .right{
      width: 50%;
      height: 100%;
      float: right;
    }
  }
  .table{
    margin-top: 10px;
    position: relative;
    overflow: hidden;
  }
  .tab-item{
    .interview{
      margin-top:20px;
      background-color: #eee;
      height: 100%;
      color:#606266;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .item{
        height: 100%;
        text-align: center;
        width: 20%;
      }
      .item:nth-child(5n){
        word-wrap: break-word;
        word-break: normal;
      }
      .lable{
        line-height: 20px;
        font-size: 12px;
      }
      .icon{
        font-size: 14px;
        color:#999;
        margin-left:5px;
        cursor: pointer;
      }
      .num{
        line-height: 30px;
        font-size: 22px;
      }
    }
  }
  .top{
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #eee;
  }
  .module{
    margin-top:10px;
    padding:15px;
    background-color: #fff;
    margin:10px 0;
  }
  .icon{
    font-size: 16px;
    color:#999;
    margin-left:10px;
    cursor: pointer;
  }
  .title{
    font-size: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    .right{
      float: right;
      margin-right: 5px;
    }
  }
}
</style>