<template>
	<div ref="echartBar" style="width:100%;height:100%;">
	</div>
</template>
<script>
	import echarts from 'echarts'
	export default {
		name: 'echartBar',
		props: {
			echartBarData: {
				type: Array,
				default: []
			},
			// legendData:{
			//   type:Array,
			//   default:[]
			// },
		},
		data() {
			return {}
		},
		mounted() {
			this.getEchart();
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		watch: {
			echartBarData(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			getEchart() {
				this.myChart = echarts.init(this.$refs.echartBar)
				let data0 = []
				let data1 = []
				data0.push(this.echartBarData[0])
				data1.push(this.echartBarData[1])
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					legend: {
						data: ['已跑路粉丝', '现有粉丝']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						axisLine: {
							show: false
						},
						type: 'value'
					},
					yAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						type: 'category',
						data: ['粉丝数']
					},
					series: [{
							name: '已跑路粉丝',
							type: 'bar',
							stack: '总量',
							barWidth: 60,
							itemStyle: {
								normal: {
									color: '#60acfc',
								}
							},
							label: {
								normal: {
									show: true,
									position: 'inside'
								}
							},
							data: data0
						},
						{
							name: '现有粉丝',
							type: 'bar',
							stack: '总量',
							barWidth: 60,
							itemStyle: {
								normal: {
									color: '#5bc49f',
								}
							},
							label: {
								normal: {
									show: true,
									position: 'inside'
								}
							},
							data: data1
						},
					]
				};
				this.myChart.setOption(option);
			}
		}
	}
</script>
