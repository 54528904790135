<template>
  <div ref="echartPie" style="width:100%;height:100%;">
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name:'echartPie',
  props: {
    echartPieData:{
      type:Array,
      default:[]
    },
    
  },
  data(){
    return {
      colors : ['#5793f3', '#d14a61', '#675bba'],
    }
  },
  mounted(){
    this.getEchart();
  },
  watch:{
    echartPieData(n,o){
      this.$nextTick(()=> {
        this.getEchart();
      });
    },
  },
  methods:{
    getEchart(){
        let legend=[]
        this.echartPieData.forEach(t => {
          legend.push(t.name)
        });
        let myChart =  echarts.init(this.$refs.echartPie)
        let option = {
          tooltip: {
              trigger: 'item',
              formatter: "{b} <br/> 人数：{c} <br/> 占比：{d} % "
          },
          legend: {
              orient: 'vertical',
              left:20,
              top:10,
              show: true,
              data:legend
          },
          color:['#60acfc','#5bc49f','#ff7c7c','#32d3eb','#feb64d','#9287e7','#FFEE58','#e57373','#BA68C8','#7986CB','#4FC3F7','#4DB6AC','#AED581','#FFF176','#FFB74D','#F06292','#9575CD','#4FC3F7','#FF8A65','#DCE775'],  
          series: [
            {
              name:'等级',
              type:'pie',
              radius: ['40%', '80%'],
              avoidLabelOverlap: false,
              label: {
                  normal: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      show: true,
                      textStyle: {
                          fontSize: '16',
                          fontWeight: 'bold'
                      }
                  }
              },
              labelLine: {
                  normal: {
                      show: false
                  }
              },
                
              data:this.echartPieData
            }
          ]
        };
        myChart.setOption(option);
    }
  }
}
</script>